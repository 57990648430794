// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-changelog-jsx": () => import("./../../../src/pages/changelog.jsx" /* webpackChunkName: "component---src-pages-changelog-jsx" */),
  "component---src-pages-formerror-jsx": () => import("./../../../src/pages/formerror.jsx" /* webpackChunkName: "component---src-pages-formerror-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */)
}

